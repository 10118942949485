import { defineStore } from 'pinia'

export type Notification = {
  id: number
  message: string
}

let nextId = 1

export const useNotificationStore = defineStore('notifications', {
  state: () => {
    return {
      notifications: [] as Notification[],
    }
  },

  actions: {
    addNotification(message: string) {
      this.notifications.push({
        id: nextId++,
        message,
      })
    },

    removeNotification(notificationToRemove: Notification) {
      this.notifications = this.notifications.filter((notification) => notification.id !== notificationToRemove.id)
    },
  },
})
