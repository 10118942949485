import { useSessionStorage } from '@vueuse/core'
import { defineStore } from 'pinia'

export const ANSWER_LIBRARY_VERSION = 'v1'
export const ANSWER_LIBRARY_STORAGE_KEY = 'answer-library'

type AnswerValue = string | CompanyFormAnswer

export type Answer<T = AnswerValue> = {
  stepId: string
  answer: T
}

type AnswerLibrary = {
  meta: {
    version: string
    updatedAt: string
    flowId?: string
  }
  answers: Answer<AnswerValue>[]
}

export type BeneficialOwner = {
  title: string
  firstName: string
  lastName: string
  shares: number
  birthday: string
  address: string
  nationality: string
  country: string
  type: string
  typeOfEconomicInterest: string
  wbSince: string
}

export type Contact = {
  email: string
  firstName: string
  lastName: string
}

export type Company = {
  companyName: string
  registryNumber: string
  registry: string
  address: string
  zip: string
  city: string
  country: string
  onrId: string
  onrIdType: string
}

export type CompanyFormAnswer = {
  contact: Contact
  manualCompanyList?: string
  companies: Company[]
}

export const useAnswerStore = defineStore('answers', {
  state: () => ({
    answerLibrary: useSessionStorage(ANSWER_LIBRARY_STORAGE_KEY, defaultLibrary()),
  }),

  getters: {
    answerForStep: (state) => {
      return (stepId: string) => state.answerLibrary.answers.find((answer) => answer.stepId === stepId)
    },
    flowId: (state) => state.answerLibrary.meta.flowId,
  },

  actions: {
    storeAnswer(stepId: string, answer: AnswerValue) {
      const otherAnswers = this.answerLibrary.answers.filter((answer) => answer.stepId !== stepId)

      otherAnswers.push({
        stepId,
        answer,
      })
      this.answerLibrary = { ...this.answerLibrary, answers: otherAnswers }
    },
    setFlowId(flowId: string) {
      this.answerLibrary.meta.flowId = flowId
    },
  },
})

function defaultLibrary(): AnswerLibrary {
  return {
    meta: {
      version: ANSWER_LIBRARY_VERSION,
      updatedAt: new Date().toISOString(),
      flowId: undefined,
    },
    answers: [],
  }
}
