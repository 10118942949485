import axios from 'axios'
import type { CompanySearchResult } from '../types'

export type SearchResponse = CompanySearchResult[]

const search = async (name: string) => {
  const body = await axios.post<SearchResponse>('/api/search', { name })
  return body.data
}

export { search }
