<template>
  <div class="overflow-x-auto">
    <OfferTableRow
      v-for="(row, index) in filterRows"
      :key="`offer_row_${index}`"
      :index="index"
      :item="row"
      :offer-type="offerType"
      :items-count="companyNames.length"
      :is-customer-portal-monitoring-active="isCustomerPortalMonitoringActive"
    />
  </div>
</template>

<script setup lang="ts">
import OfferTableRow from './OfferTableRow.vue'
import { computed } from 'vue'
import type { OfferRow } from '../../types'
import { useOfferStore } from '@/questionnaire/stores/offer'

const offerStore = useOfferStore()

const props = defineProps<{ rows: OfferRow[]; offerType: 'offer_1' | 'offer_2' | 'offer_3' | 'offer_4'; companyNames: string[] }>()

const isCustomerPortalMonitoringActive = computed(
  () => !!props.rows.find((row) => row.priceTypeCode === 'yearlySum' && offerStore.yearlyPricesSelected(props.offerType))
)

const filterRows = computed(() => {
  return props.rows.filter((row) => {
    if (row.priceTypeCode === 'yearlySum') {
      return offerStore.yearlyPricesSelected(props.offerType)
    }
    return true
  })
})
</script>
