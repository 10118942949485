<template>
  <div class="bg-yellow-200 p-4">
    {{ notification.message }}
  </div>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref, type PropType, type Ref } from 'vue'
import { useNotificationStore, type Notification } from '../stores/notifications'

const timeout: Ref<number | null> = ref(null)

const notificationStore = useNotificationStore()
const { removeNotification } = notificationStore

const props = defineProps({
  notification: {
    type: Object as PropType<Notification>,
    required: true,
  },
})

onMounted(() => {
  timeout.value = window.setTimeout(() => removeNotification(props.notification), 5000)
})

onBeforeUnmount(() => {
  if (!timeout.value) return

  window.clearTimeout(timeout.value)
})
</script>
