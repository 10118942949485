<template>
  <div>
    <h3>Unser Angebot für Sie</h3>
    <div class="mt-4">
      <slot :company-names="companyNames"></slot>
      <OfferTable :rows="rows" :offer-type="offerType" :company-names="companyNames" />
    </div>

    <div class="mt-8">
      <div class="mb-4">
        <label>
          <input v-model="acceptTerms" type="checkbox" />
          Hiermit bestätige ich die Richtigkeit vorstehender Angaben, die
          <a href="/mandatsbedingungen" target="_blank">Mandatsbedingungen</a>
          sowie die Mandatserteilung zu den dargestellten Konditionen<span class="text-red-500">*</span>
        </label>
      </div>
      <div class="mb-4">
        <label>
          <input v-model="acceptDataProtectionTerms" type="checkbox" />
          Hiermit bestätige ich, die <a href="https://www.ypog.law/datenschutz" target="_blank">Datenschutzhinweise</a> gelesen zu haben und
          mit der darin beschriebenen Datennutzung einverstanden zu sein
          <span class="text-red-500">*</span>
        </label>
      </div>
      <div v-if="companyNames.length > 1" class="mb-8">
        <label>
          <input v-model="authorizedToRepresent" type="checkbox" />
          Hiermit bestätige ich, dass ich für die oben genannten Gesellschaften vertretungsberechtigt bin.
        </label>
      </div>
      <button class="btn-secondary mr-4" :disabled="submitting" @click.stop="emit('back')">Zurück</button>
      <button class="btn" :disabled="!submittable" @click.stop="acceptOffer">
        {{ submitting ? 'Auftrag wird eingereicht...' : 'Zahlungspflichtig bestellen' }}
      </button>
    </div>
  </div>
</template>
<script setup lang="ts">
type Props = {
  offerType: 'offer_1' | 'offer_2' | 'offer_3' | 'offer_4'
}
import { ref, computed, onMounted } from 'vue'
import axios from 'axios'
import { storeToRefs } from 'pinia'
import { useAnswerStore, CompanyFormAnswer } from '../stores/answers'
import { useOfferStore } from '../stores/offer'
import { useQuestionnaireStore } from '../stores/questionnaire'
import { useNotificationStore } from '../stores/notifications'
import OfferTable from './OfferTable.vue'
import mixpanel from 'mixpanel-browser'
import { Step } from '../stores/questionnaire'
import { Answer } from '../stores/answers'
// STORE SETUP
const answerStore = useAnswerStore()
const { answerLibrary } = storeToRefs(answerStore)
const questionnaireStore = useQuestionnaireStore()
const { questionForAnswer } = questionnaireStore
const { path } = storeToRefs(questionnaireStore)
const notificationStore = useNotificationStore()
const { addNotification } = notificationStore
const offerStore = useOfferStore()
type QuestionWithAnswer = {
  question: Step
  answer: Answer
}
const props = defineProps<Props>()
const acceptTerms = ref(false)
const acceptDataProtectionTerms = ref(false)
const authorizedToRepresent = ref(false)
const submitting = ref(false)
const rows = computed(() => offerStore.offerForType(props.offerType))
const emit = defineEmits<{
  (e: 'back'): void
}>()
const submittable = computed(() => {
  return (
    acceptTerms.value &&
    acceptDataProtectionTerms.value &&
    (companyNames.value.length === 1 || authorizedToRepresent.value) &&
    !submitting.value
  )
})
onMounted(() => {
  mixpanel.track('showOffer', {
    offerType: props.offerType,
  })
  if (answerLibrary.value.meta?.flowId === 'external') {
    offerStore.selectAllLineItems(props.offerType)
  }
})
const hsRelevantQuestionsWithAnswers = computed(() => {
  return answerLibrary.value.answers
    .filter((answer) => path.value.includes(answer.stepId))
    .map((answer) => {
      return {
        answer,
        question: questionForAnswer(answer),
      } as QuestionWithAnswer
    })
})
const companyNames = computed(() => {
  const companyFormAnswer = answerLibrary.value.answers.filter((answer) => (answer?.answer as CompanyFormAnswer)?.companies)[0]
    ?.answer as CompanyFormAnswer
  if (companyFormAnswer) {
    const companies = companyFormAnswer.companies.map((company) => company.companyName)
    if (companyFormAnswer.manualCompanyList) {
      companies.push(...companyFormAnswer.manualCompanyList.split('\n'))
    }
    return companies
  }
  return []
})
const acceptOffer = () => {
  mixpanel.track('acceptOffer', {
    offerType: props.offerType,
  })
  submitting.value = true
  axios
    .post('/api/offers', {
      answers: hsRelevantQuestionsWithAnswers.value.map((data) => data.answer),
      line_items: offerStore.selectedLineItems(props.offerType),
      flow_id: answerLibrary.value.meta.flowId,
      offer_type: props.offerType,
    })
    .then((response) => {
      window.location.href = response.data.redirect_to
      submitting.value = false
    })
    .catch(() => {
      submitting.value = false
      addNotification('Das Angebot konnte nicht erstellt werden. Bitte versuchen Sie es erneut oder wenden sie sich an unseren support')
    })
}
</script>
