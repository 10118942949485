<template>
  <h2 class="mb-4">Unternehmen und Kontaktperson</h2>
  <fieldset class="mb-4">
    <legend class="font-bold">Ansprechpartner</legend>
    <TextInput v-model="contact.email" label="E-Mail Kontaktperson" required email placeholder="email@example.com" />
    <TextInput v-model="contact.firstName" label="Vorname Kontaktperson" required />
    <TextInput v-model="contact.lastName" label="Nachname Kontaktperson" required />
  </fieldset>
  <div v-if="flowId === 'external'">
    <SelectInput
      v-model="moreThanTenCompaniesWanted"
      label="Möchten Sie den Service für mehr als 10 Gesellschaften beauftragen?"
      :options="{
        '1': 'Ja',
        '0': 'Nein',
      }"
      class="mb-4"
    >
    </SelectInput>
    <CompanySelect :disabled="moreThanTenCompaniesWanted === '1' && companies.length >= 1" @add-company="addCompany"></CompanySelect>
    <div v-show="moreThanTenCompaniesWanted === '1'" class="mb-4">
      <TextArea v-model="manualCompanyList" label="Weitere Unternehmen" required hint="Ein Unternehmen pro Zeile" />
    </div>
  </div>
  <div v-else-if="flowId === 'internal'">
    <CompanySelect @add-company="addCompany"></CompanySelect>
  </div>
  <div v-else>
    <CompanySelect :disabled="companies.length >= 1" @add-company="addCompany"></CompanySelect>
  </div>
  <div></div>
  <div v-if="companies.length > 0" class="mb-2">
    <legend class="font-bold">Unternehmen</legend>
    <div v-for="company in companies" :key="company.onrId" class="p-2 mb-2 border flex justify-between">
      <div>
        <strong>{{ company.companyName }}</strong
        ><br />
        {{ company.address }}<br />
        {{ company.zip }} {{ company.city }}<br />
        {{ company.country }}
      </div>
      <div class="underline cursor-pointer" @click="(_: MouseEvent) => removeCompany(company)">Entfernen</div>
    </div>
  </div>
  <div class="mt-8">
    <button class="btn-secondary" @click.stop="emit('back')">Zurück</button>

    <button class="btn ml-4" :disabled="stepCompleted" @click.stop="goForward">Weiter</button>
  </div>
</template>

<script setup lang="ts">
type StepComponentProps = {
  id: string
  type?: string
  info?: string
  body?: string
  title?: string
  name?: string
  yesLabel?: string
  noLabel?: string
  flowId: string
  isFirstStep: boolean
}
import { onMounted, computed, ref, watch } from 'vue'
import { useAnswerStore, Answer, Contact, CompanyFormAnswer, Company } from '../stores/answers'
import TextInput from '../components/TextInput.vue'
import TextArea from '../components/TextArea.vue'
import CompanySelect from '../components/CompanySelect.vue'
import SelectInput from '../components/SelectInput.vue'
const { answerForStep, storeAnswer } = useAnswerStore()
const props = withDefaults(defineProps<StepComponentProps>(), { isFirstStep: false })
const manualCompanyList = ref<string | undefined>(undefined)
const companies = ref<Company[]>([])
const contact = ref<Contact>({
  email: '',
  firstName: '',
  lastName: '',
})
const moreThanTenCompaniesWanted = ref<'0' | '1'>('0')
onMounted(() => {
  const answer = answerForStep(props.id) as Answer<CompanyFormAnswer>
  manualCompanyList.value = answer?.answer?.manualCompanyList
  companies.value = answer?.answer?.companies || []
  contact.value = answer?.answer?.contact || { email: '', firstName: '', lastName: '' }
  moreThanTenCompaniesWanted.value = answer?.answer?.manualCompanyList && answer.answer.manualCompanyList.length > 1 ? '1' : '0'
})
watch(moreThanTenCompaniesWanted, (newValue, oldValue) => {
  if (oldValue === newValue) {
    return
  } else if (newValue === '1') {
    companies.value = companies.value.slice(companies.value.length - 1, companies.value.length)
  } else {
    manualCompanyList.value = undefined
  }
})
const addCompany = (company: Company) => {
  if (companies.value.find((c) => c.onrId === company.onrId)) {
    return
  }
  companies.value.unshift(company)
}
const removeCompany = (company: Company) => {
  companies.value = companies.value.filter((c) => c.onrId !== company.onrId)
}
const stepCompleted = computed(() => {
  return !contact.value.email || !contact.value.firstName || !contact.value.lastName || companies.value.length === 0
})
const emit = defineEmits<{
  (e: 'back'): void
  (e: 'completed'): void
}>()
const goForward = () => {
  storeAnswer(props.id, {
    contact: contact.value,
    companies: companies.value,
    manualCompanyList: manualCompanyList.value,
  })
  emit('completed')
}
</script>
