<template>
  <h2 class="mb-4">Sie haben die falsche Gesellschaftsform ausgewählt</h2>

  <div class="mb-4">
    Ihre Angaben passen nicht zu der von Ihnen ausgewählten Gesellschaftsform. Bitte überprüfen Sie Ihre Angaben oder wählen Sie eine andere
    Gesellschaftsform aus.
  </div>
  <div>
    <button class="btn-secondary mr-4" @click.stop="emit('back')">Zurück</button>
    <button class="btn" @click.stop="restart">Andere Gesellschaftsform Auswählen</button>
  </div>
</template>

<script setup lang="ts">
import { inject } from 'vue'
import { Restart } from '../injectionSymbols'

defineProps({
  id: {
    type: String,
    required: true,
  },
  type: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  body: {
    type: String,
    required: true,
  },
  flowId: {
    type: String,
    required: true,
  },
  isFirstStep: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const restart = inject(Restart)

const emit = defineEmits<{
  (e: 'back'): void
  (e: 'completed'): void
}>()
</script>
