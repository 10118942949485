import { useSessionStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { useAnswerStore, type Answer } from './answers'

export const PATH_STORAGE_KEY = 'questionnaire-path'
export const FINGERPRINT_STORAGE_KEY = 'questionnaire-fingerprint'

export type Step = {
  id: string
  answers?: Record<string, string>
  nextStepId?: string
  name: string
  title: string
  info?: string
  type:
    | 'boolean'
    | 'company_form'
    | 'info_final'
    | 'summary'
    | 'offer_simple'
    | 'offer_complex'
    | 'offer_individual'
    | 'offer_inconsistency_report'
    | 'wrong_type'
  body?: string
  yesLabel?: string
  noLabel?: string
}

type StepId = string

type QuestionnaireConfiguration = {
  steps: Record<StepId, Step>
  meta: {
    firstStep: string
    fingerprint: string
  }
}

export const useQuestionnaireStore = defineStore('questionnaire', {
  state: () => {
    return {
      configuration: null as QuestionnaireConfiguration | null,
      path: useSessionStorage(PATH_STORAGE_KEY, [] as string[]),
    }
  },

  actions: {
    refreshPath() {
      if (this.path.length || !this.configuration?.meta.firstStep) return

      this.appendPath(this.configuration.meta.firstStep)
    },

    appendPath(stepId: string) {
      this.path.push(stepId)
    },

    proceedToNextStep() {
      const currentStepId = this.currentStep?.id
      if (!currentStepId) {
        console.error('could not load current step id')
        return
      }

      const step = this.configuration?.steps[currentStepId]

      if (step?.nextStepId) {
        this.appendPath(step.nextStepId)
      } else if (step?.answers) {
        if (!this.answerForCurrentStep) {
          console.error(`Could not find answer for step id: ${this.currentStep?.id}`)
          return
        }

        const nextPath = step.answers[this.answerForCurrentStep.answer as string]
        if (!nextPath) {
          console.error(`Could not find next step for answer: ${this.answerForCurrentStep.answer}. Step id: ${this.currentStep?.id}`)
          return
        }
        this.appendPath(nextPath)
      }
    },

    goBackToPreviousStep() {
      this.path.pop()
    },

    setQuestionnaireConfiguration(newConfiguration: QuestionnaireConfiguration) {
      this.configuration = newConfiguration

      sessionStorage.setItem(FINGERPRINT_STORAGE_KEY, newConfiguration.meta.fingerprint)
    },
  },

  getters: {
    isFirstStep: (state) => state.path.length === 1,

    currentStep: (state) => {
      if (!state.path.length || !state.configuration) return

      const lastPath = state.path[state.path.length - 1]
      const step = state.configuration.steps[lastPath]
      if (!step) return // FIXME: log error

      return step
    },

    answerForCurrentStep() {
      if (!this.currentStep) return

      const answers = useAnswerStore()
      const answer = answers.answerForStep(this.currentStep.id)

      return answer
    },

    questionForAnswer: (state) => {
      return (answer: Answer) => {
        return state.configuration?.steps[answer.stepId]
      }
    },
  },
})
