<template>
  <h1 class="mt-4 mb-4 lg:mb-6 text-sans text-base md:text-xl lg:text-4xl break-words">Transparenzregister-Service by YSolutions</h1>

  <h3 class="mt-4">Bitte wählen Sie aus den Leistungen aus:</h3>

  <div class="mt-4">
    <ul>
      <li>
        <label id="initial-reporting" class="rounded block border-gray-600 border p-5 mb-2">
          <input v-model="serviceId" class="mr-4" type="radio" value="initial-reporting" autocomplete="off" />
          Erstmeldung einer Gesellschaft
        </label>
      </li>

      <li>
        <label id="followup" class="rounded block border-gray-600 border p-5 mb-2">
          <input v-model="serviceId" class="mr-4" type="radio" value="followup" autocomplete="off" />
          Folgemeldungen (bereits gemeldet, neue Meldung muss gemacht werden)
        </label>
      </li>

      <li>
        <label id="change" class="rounded block border-gray-600 border p-5 mb-2">
          <input v-model="serviceId" class="mr-4" type="radio" value="inconsistency-report" autocomplete="off" />
          Unstimmigkeitsmeldung
        </label>
      </li>

      <li>
        <label id="monitoring" class="rounded block border-gray-600 border p-5 mb-2">
          <input v-model="serviceId" class="mr-4" type="radio" value="monitoring" autocomplete="off" />
          Fortlaufendes Monitoring
        </label>
      </li>
    </ul>
  </div>

  <button class="mt-4 btn" :disabled="!serviceId" @click.stop="nextStep">Weiter</button>
</template>

<script setup lang="ts">
import { ref, type Ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const serviceId: Ref<string | null> = ref(null)

const nextStep = () => {
  switch (serviceId.value) {
    case 'initial-reporting':
      router.push({ name: 'company-form' })
      break
    case 'inconsistency-report':
      router.push({ name: 'questionnaire', params: { flowId: 'unstimmigkeitsmeldung' } })
      break
    default:
      router.push({ name: 'questionnaire', params: { flowId: 'individuell' } })
  }
}
</script>
