import { createRouter, createWebHashHistory } from 'vue-router'
import CompanyFormPage from './pages/HomePage.vue'
import ChoiceOfServicePage from './pages/ChoiceOfServicePage.vue'
import QuestionnaireWizzard from './pages/QuestionnaireWizzard.vue'

const routes = [
  {
    path: '/',
    name: 'choice-of-service',
    component: ChoiceOfServicePage,
  },
  {
    path: '/dienstleistung',
    name: 'company-form',
    component: CompanyFormPage,
  },
  {
    path: '/dienstleistung/:flowId',
    name: 'questionnaire',
    component: QuestionnaireWizzard,
    props: true,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

export default router
