<template>
  <div>
    <label v-if="label" class="block" :class="computedClass">{{ label }}</label>
    <select class="w-full" :value="modelValue" v-bind="$attrs" @change="updateValue" @blur="v$.$validate()">
      <option v-for="(option, value) in options" :key="value" :value="value">{{ option }}</option>
    </select>
  </div>

  <span v-if="v$.$dirty" class="text-red-400">
    {{ v$.$silentErrors[0]?.$message }}
  </span>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required as requiredValidator, helpers } from '@vuelidate/validators'

export default defineComponent({
  inheritAttrs: false,
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object as PropType<Record<string, string>>,
      default: {} as PropType<Record<string, string>>,
    },
    email: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  validations() {
    return {
      modelValue: {
        ...(this.required ? { requiredValidator: helpers.withMessage('muss ausgefüllt sein', requiredValidator) } : []),
      },
    }
  },
  computed: {
    computedClass() {
      return this.required ? "after:content-['*'] after:text-action after:pl-1" : null
    },
  },
  methods: {
    updateValue(event: Event) {
      this.$emit('update:modelValue', (event.target as HTMLInputElement).value)
    },
  },
})
</script>
