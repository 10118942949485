import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

import HoneybadgerVue from '@honeybadger-io/vue'
import mixpanel from 'mixpanel-browser'
mixpanel.init('8e4ee2f249d5d149e2ab67003ebadf98')

const config = {
  apiKey: 'hbp_YXPOWIMITAvlS9ULCwRBPX7xFYbyoI1Y0bi4',
  environment: 'production',
}

export default {
  mount: (element) => {
    const app = createApp(App)
    const pinia = createPinia()

    if (process.env.NODE_ENV === 'production') {
      app.use(HoneybadgerVue, config)
    }

    app.use(pinia)
    app.use(router)

    window.mixpanel = mixpanel
    app.mount(element)
  },
}
