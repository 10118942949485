<template>
  <h3 class="text-base md:text-xl lg:text-2xl">{{ title }}</h3>

  <div class="grid" :class="[info ? 'lg:grid-cols-5 lg:space-x-2' : '']">
    <div class="lg:col-span-3">
      <label
        id="yes"
        class="rounded flex items-center space-x-2 border-gray-300 focus:ring focus:ring-violet-300 focus:outline-none border p-5 mb-2"
      >
        <input v-model="booleanAnswer" type="radio" value="yes" autocomplete="off" />
        <div class="inline-flex items-center">
          {{ yesLabel }}
        </div>
      </label>

      <label
        id="no"
        class="rounded flex items-center space-x-2 border-gray-300 focus:ring focus:ring-violet-300 focus:outline-none border p-5 mb-2"
      >
        <input v-model="booleanAnswer" type="radio" value="no" autocomplete="off" />
        <div class="">
          {{ noLabel }}
        </div>
      </label>
    </div>

    <div v-if="info" class="invisible" :class="[info ? 'lg:visible lg:col-span-2' : '']">
      <div class="rounded block border-accent/60 border p-5">
        <div class="font-bold">Hinweis:</div>
        <!-- eslint-disable-next-line vue/no-v-html -->
        <p class="flex flex-col space-y-4 prose" v-html="info"></p>
      </div>
    </div>
  </div>

  <div class="mt-4">
    <button v-if="!isFirstStep" class="btn-secondary mr-4" @click.stop="emit('back')">Zurück</button>

    <button class="btn" :disabled="!booleanAnswer" @click.stop="emit('completed')">Weiter</button>
  </div>
</template>

<script setup lang="ts">
type StepComponentProps = {
  id: string
  type?: string
  info?: string
  body?: string
  title?: string
  name?: string
  yesLabel?: string
  noLabel?: string
  flowId: string
  isFirstStep: boolean
}
import { onMounted, ref, watchEffect, Ref } from 'vue'
import { useAnswerStore } from '../stores/answers'
const { answerForStep, storeAnswer } = useAnswerStore()
const props = withDefaults(defineProps<StepComponentProps>(), { isFirstStep: false, yesLabel: 'Ja', noLabel: 'Nein', info: undefined })
onMounted(() => {
  const answer = answerForStep(props.id)
  if (answer) booleanAnswer.value = answer.answer as string
})
const booleanAnswer: Ref<string | undefined> = ref(undefined)
const emit = defineEmits<{
  (e: 'back'): void
  (e: 'completed'): void
}>()
watchEffect(() => {
  if (!booleanAnswer.value) return
  storeAnswer(props.id, booleanAnswer.value)
})
</script>
