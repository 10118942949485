<template>
  <div>
    <NotificationBar v-for="notification in notifications" :key="notification.id" :notification="notification" />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import NotificationBar from './NotificationBar.vue'
import { useNotificationStore } from '../stores/notifications'

const notificationStore = useNotificationStore()
const { notifications } = storeToRefs(notificationStore)
</script>
