<template>
  <div class="fixed bottom-0 left-0 right-0 font-mono bg-gray-300 text-[10px] p-4">
    <h6 class="text-sm">Debug</h6>

    <div>Path: {{ path.join(' / ') }}</div>
    <div>First step: {{ isFirstStep }}</div>

    <button v-if="showAnswers" class="font-bold" @click="showAnswers = false">hide answers</button>
    <button v-else class="font-bold" @click="showAnswers = true">show answers</button>

    <pre v-if="showAnswers">{{ answerLibrary }}</pre>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useQuestionnaireStore } from './stores/questionnaire'
import { useAnswerStore } from './stores/answers'
import { ref } from 'vue'

const showAnswers = ref(false)

const questionnaireStore = useQuestionnaireStore()
const { path, isFirstStep } = storeToRefs(questionnaireStore)

const answerStore = useAnswerStore()
const { answerLibrary } = storeToRefs(answerStore)
</script>
