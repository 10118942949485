<template>
  <div>
    <label v-if="label" class="block" :class="computedClass">{{ label }}</label>
    <textarea
      class="w-full rounded"
      type="text"
      :value="modelValue"
      v-bind="$attrs"
      autocomplete="off"
      @input="updateValue"
      @blur="v$.$touch"
    />
  </div>

  <span v-if="v$.$dirty" class="text-red-400">
    {{ v$.$silentErrors[0]?.$message }}
  </span>

  <span v-if="hint" class="text-gray-500 block mt-1 text-xs">{{ hint }}</span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required as requiredValidator, helpers } from '@vuelidate/validators'

export default defineComponent({
  inheritAttrs: false,
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    email: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: null,
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
  },
  emits: ['update:modelValue'],
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  validations() {
    return {
      modelValue: {
        ...(this.required ? { requiredValidator: helpers.withMessage('muss ausgefüllt sein', requiredValidator) } : []),
      },
    }
  },
  computed: {
    computedClass() {
      return this.required ? "after:content-['*'] after:text-action after:pl-1" : null
    },
  },
  methods: {
    updateValue(event: Event) {
      this.$emit('update:modelValue', (event.target as HTMLInputElement).value)
    },
  },
})
</script>
