<template>
  <div class="mb-8" :class="[item.highlight ? 'bg-slate-100' : '']">
    <div class="mb-4">
      <div v-if="item.lineItem && !item.disabled" class="text-lg font-bold underline">Optional buchbar</div>
      <div @click="toggleSelect">
        <div class="flex items-center space-x-2 mb-4" :class="[item.lineItem && !item.disabled ? 'cursor-pointer' : '']">
          <div v-if="item.lineItem" class="h-5 w-5 border-2 border-slate-700 flex justify-center items-center">
            <CheckIcon v-if="item.selected" class="text-green-600 w-40" />
          </div>
          <h4 v-if="itemHeadline" class="mx-2" :class="[item.highlight ? 'mt-2' : '']">{{ itemHeadline }}</h4>
        </div>

        <div class="md:w-3/4">
          <ul v-if="item.bullets && (!item.lineItem || (item.lineItem && item.selected))" class="pl-4">
            <li v-for="(bullet, n) in item.bullets" :key="`offer_bullet_${index}_${n}`" class="flex items-start">
              <ul v-if="Array.isArray(bullet)" class="pl-4">
                <li v-for="(subBullet, m) in bullet" :key="`offer_bullet_${index}_${n}_${m}`" class="flex items-start">
                  <ChevronRightIcon class="w-4 h-6 mr-2 shrink-0" />
                  <div>{{ subBullet }}</div>
                </li>
              </ul>
              <template v-else>
                <component :is="componentToRender" class="w-4 h-6 mr-2 shrink-0" :class="iconClass" />
                <div>{{ bullet }}</div>
              </template>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="w-full lg:flex lg:justify-end">
      <table class="table-fixed w-full xl:w-3/4">
        <thead>
          <tr>
            <th>Einheit</th>
            <th class="text-right">Preis</th>

            <th v-if="!['individual_offer_entry', 'inconcistency_report_offer'].includes(item.code as string)" class="text-right">Summe</th>
          </tr>
        </thead>
        <tbody>
          <tr class="border-b border-gray-600">
            <td :class="{ 'font-bold bg-slate-200': item.highlight }" class="align-text-top">{{ item.priceType }}</td>
            <td :class="{ 'font-bold bg-slate-200': item.highlight }" class="align-text-top text-right">
              {{ item.hideItemCountMultiplier ? item.alternativeToMultiplier : `${itemsCount} x` }}
              {{ displayPrice(price) }}
            </td>
            <td
              v-if="!['individual_offer_entry', 'inconsistency_report_offer'].includes(item.code as string)"
              :class="{ 'font-bold bg-slate-200': item.highlight }"
              class="align-text-top text-right"
            >
              {{ displayPrice(priceSum) }} <br />
              <div class="xl:w-3/4 xl:ml-auto foot-note text-sm font-light">{{ sumPostFixText }}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CheckIcon, PlusCircleIcon, XCircleIcon, ChevronRightIcon } from '@heroicons/vue/solid'
import { computed } from 'vue'
import type { OfferRow } from '@/types'
import { useOfferStore } from '@/questionnaire/stores/offer'

const offerStore = useOfferStore()

const props = defineProps<{
  item: OfferRow
  index: number
  isCustomerPortalMonitoringActive: boolean
  offerType: 'offer_1' | 'offer_2' | 'offer_3' | 'offer_4'
  itemsCount: number
}>()

const iconClass = computed(() => {
  switch (props.item.type) {
    case 'included':
      return 'text-green-600'
    case 'notincluded':
      return 'text-red-600'
    case 'optional':
      return 'text-yellow-600'
    default:
      return 'text-green-600'
  }
})

const formatter = new Intl.NumberFormat('de-DE', {
  style: 'currency',
  currency: 'EUR',
})

const toggleSelect = () => {
  if (typeof props.item.id === 'undefined') return

  if (props.item.lineItem) {
    offerStore.toggleLineItem(props.offerType, props.item.id)
  }
}

const itemsCount = computed(() => {
  if (props.itemsCount > 1) {
    return props.itemsCount
  } else {
    return 1
  }
})

const itemHeadline = computed(() =>
  typeof props.item.headline === 'function'
    ? props.item.headline(props.isCustomerPortalMonitoringActive ? 'FirstYear' : '')
    : props.item.headline
)

const sumPostFixText = computed(() => props.item.sumPostFixText || '')

const price = computed(() => {
  let priceCents = props.item.priceCents
  let priceTypeCode = props.item.priceTypeCode

  switch (priceTypeCode) {
    case 'yearlySum':
      priceCents = offerStore.calculateYearlyPrice(props.offerType)
      break
    case 'totalSum':
      priceCents = offerStore.calculatePrice(props.offerType)
      break
    default:
      priceCents = props.item.priceCents || 0
      break
  }

  if (priceCents === 0) {
    return 0
  } else {
    return priceCents / 100
  }
})

const priceSum = computed(() => {
  return price.value * itemsCount.value
})

const displayPrice = (price: number) => {
  if (price == 0) {
    return 'incl.'
  }

  const priceStr = formatter.format(price)
  return `${props.item.pricePrefix || ''}${priceStr}`
}

const componentToRender = computed(() => {
  switch (props.item.type) {
    case 'included':
      return CheckIcon
    case 'notincluded':
      return XCircleIcon
    case 'optional':
      return PlusCircleIcon
    default:
      return CheckIcon
  }
})
</script>
