import type { OfferRow } from '@/types'
import { defineStore } from 'pinia'

import * as offersData from './data/offers_data'

const offerMapping: Record<'offer_1' | 'offer_2' | 'offer_3' | 'offer_4', 'simple' | 'complex' | 'individual' | 'inconsistencyReport'> = {
  offer_1: 'simple',
  offer_2: 'complex',
  offer_3: 'individual',
  offer_4: 'inconsistencyReport',
}

const preselectedLineItems = (lineItems: OfferRow[]) => {
  const currentStorage = sessionStorage.getItem('line_items')?.split(',')

  const selectedItems = lineItems.map((lineItem: OfferRow) => {
    if (typeof lineItem.code === 'undefined') {
      return lineItem
    }

    if (currentStorage?.includes(lineItem.code)) {
      return { ...lineItem, selected: true, disabled: true }
    }
    return lineItem
  })

  return selectedItems
}

export const useOfferStore = defineStore('offer', {
  state: () =>
    ({
      simple: preselectedLineItems(offersData.simpleOffer),
      complex: preselectedLineItems(offersData.complexOffer),
      individual: preselectedLineItems(offersData.individualOffer),
      inconsistencyReport: preselectedLineItems(offersData.inconsistencyReportOffer),
    } as Record<'simple' | 'complex' | 'individual' | 'inconsistencyReport', OfferRow[]>),
  getters: {
    offerForType: (state) => {
      return (type: 'offer_1' | 'offer_2' | 'offer_3' | 'offer_4'): OfferRow[] => state[offerMapping[type]]
    },
    calculatePrice: (state) => {
      return (type: 'offer_1' | 'offer_2' | 'offer_3' | 'offer_4'): number => {
        let acc = 0
        state[offerMapping[type]].forEach((row) => {
          if (
            row.lineItem &&
            row.selected &&
            ['fixed', 'yearly'].includes(row.priceTypeCode || '') &&
            !['individual_offer_entry', 'inconsistency_report_offer'].includes(row?.code || '') // filtering out the fixed cost per company
          ) {
            acc += row.priceCents || 0
          }
        })

        return acc
      }
    },
    calculateYearlyPrice: (state) => {
      return (type: 'offer_1' | 'offer_2' | 'offer_3' | 'offer_4'): number => {
        let acc = 0
        state[offerMapping[type]].forEach((row) => {
          if (
            row.lineItem &&
            row.selected &&
            ['yearly'].includes(row.priceTypeCode || '') &&
            !['individual_offer_entry', 'inconsistency_report_offer'].includes(row?.code || '') // filtering out the fixed cost per company
          ) {
            acc += row.priceCents || 0
          }
        })

        return acc
      }
    },
    yearlyPricesSelected: (state) => {
      return (type: 'offer_1' | 'offer_2' | 'offer_3' | 'offer_4'): boolean => {
        let selected = false
        state[offerMapping[type]].forEach((row) => {
          if (row.lineItem && row.selected && row.priceTypeCode === 'yearly') {
            selected = true
          }
        })

        return selected
      }
    },
    toggleLineItem: (state) => {
      return (type: 'offer_1' | 'offer_2' | 'offer_3' | 'offer_4', id: number): void => {
        state[offerMapping[type]].forEach((row) => {
          if (typeof row.id === 'undefined') return

          if (row.id === id && !row.disabled) {
            row.selected = !row.selected
          }
        })
      }
    },
    selectedLineItems: (state) => {
      return (type: 'offer_1' | 'offer_2' | 'offer_3' | 'offer_4'): string[] => {
        return state[offerMapping[type]]
          .filter((row) => row.selected)
          .map((row) => row.code)
          .filter((code) => typeof code !== 'undefined') as string[]
      }
    },
  },
  actions: {
    selectAllLineItems(type: 'offer_1' | 'offer_2' | 'offer_3' | 'offer_4'): void {
      this[offerMapping[type]].forEach((row) => {
        if (!row.lineItem) return
        row.selected = true
      })
    },
  },
})
