<template>
  <a @click.stop="restart">Von vorn beginnen</a>

  <h1 class="my-4 lg:my-6 text-sans break-words text-base md:text-xl lg:text-4xl">Transparenzregister-Service by YSolutions</h1>

  <div v-if="componentForDisplay.component">
    <component
      :is="componentForDisplay.component"
      v-bind="componentForDisplay.props"
      :key="`step_id_${currentStep?.id}`"
      @completed="stepCompleted"
      @back="stepBack"
    />
  </div>

  <DebugPanel v-if="showDebugPanel" />
</template>

<script setup lang="ts">
import axios from 'axios'
import { computed, onMounted, provide, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'

import BooleanQuestion from '@/questionnaire/steps/BooleanQuestion.vue'
import CompanyForm from '@/questionnaire/steps/CompanyForm.vue'
import InfoFinal from '@/questionnaire/steps/InfoFinal.vue'
import DebugPanel from '@/questionnaire/DebugPanel.vue'
import OfferSimple from '@/questionnaire/steps/OfferSimple.vue'
import OfferComplex from '@/questionnaire/steps/OfferComplex.vue'
import OfferIndividual from '@/questionnaire/steps/OfferIndividual.vue'
import OfferInconsistencyReport from '@/questionnaire/steps/OfferInconsistencyReport.vue'
import WrongType from '@/questionnaire/steps/WrongType.vue'

import { Restart } from '@/questionnaire/injectionSymbols'

import { useQuestionnaireStore, PATH_STORAGE_KEY, FINGERPRINT_STORAGE_KEY } from '@/questionnaire/stores/questionnaire'
import { ANSWER_LIBRARY_VERSION, ANSWER_LIBRARY_STORAGE_KEY, useAnswerStore } from '@/questionnaire/stores/answers'
import { useNotificationStore } from '@/questionnaire/stores/notifications'
import * as _ from 'lodash'
import mixpanel from 'mixpanel-browser'
import type { StepComponentProps } from '../types'

const questionnaireStore = useQuestionnaireStore()
const { isFirstStep, currentStep } = storeToRefs(questionnaireStore)
const { setQuestionnaireConfiguration, refreshPath, goBackToPreviousStep, proceedToNextStep } = questionnaireStore

const answerStore = useAnswerStore()
const { setFlowId } = answerStore

const notificationStore = useNotificationStore()
const { addNotification } = notificationStore

const props = defineProps<{ flowId: string }>()

const router = useRouter()
const route = useRoute()

const showDebugPanel = computed(() => route.query.debug === 'true')

watch(
  () => currentStep.value,
  (newStep) => {
    if (newStep) trackPageView(newStep?.id)
  }
)

onMounted(() => {
  trackPageView('start')
  validateAnswerLibrary()

  // Fetch questionnaire
  axios
    .get('/api/questionnaire', { params: { flow_id: props.flowId } })
    .then((response) => {
      if (sessionStorage.getItem(FINGERPRINT_STORAGE_KEY) !== response.data.meta.fingerprint) resetQuestionnaire()
      // FIXME: flow id seems to be overwritten with "gmbh" every time
      setFlowId(props.flowId)
      setQuestionnaireConfiguration(response.data)
    })
    .then(() => {
      refreshPath()
    })
    .catch((error) => {
      console.error(error)

      addNotification("Couldn't load questions")
    })
})

const trackPageView = (pageId: string) => {
  const constructedPath = `/fragebogen#/gesellschaftsform/${props.flowId}/${pageId}`
  mixpanel.track(`Step ${pageId}`, {
    flowId: props.flowId,
    pageId: pageId,
  })
  var _hsq = (window._hsq = window._hsq || [])
  _hsq.push(['setPath', constructedPath])
  _hsq.push(['trackPageView'])
}

const restart = () => {
  resetQuestionnaire()
  mixpanel.track('restart')
  redirectToAfterReset()
}

provide(Restart, restart)

const componentForDisplay = computed(() => {
  if (!currentStep?.value) return { component: null, props: {} }

  const componentProps: StepComponentProps = {
    ..._.pick(currentStep.value, ['id', 'type', 'info', 'body', 'title', 'name', 'yesLabel', 'noLabel']),
    flowId: props.flowId,
    isFirstStep: isFirstStep.value,
  }

  return {
    component: currentComponent(),
    props: componentProps,
  }
})

const redirectToAfterReset = (): void => {
  if (props.flowId === 'internal') {
    router.push({ name: 'questionnaire', params: { flowId: 'internal' } }).then(() => {
      window.location.reload()
    })
  } else {
    router.push({ name: 'choice-of-service' })
  }
}

const currentComponent = () => {
  if (!currentStep.value) return

  const { type } = currentStep.value

  if (type === 'boolean') {
    return BooleanQuestion
  } else if (type === 'company_form') {
    return CompanyForm
  } else if (type === 'info_final') {
    return InfoFinal
  } else if (type === 'offer_simple') {
    return OfferSimple
  } else if (type === 'offer_complex') {
    return OfferComplex
  } else if (type === 'offer_individual') {
    return OfferIndividual
  } else if (type === 'offer_inconsistency_report') {
    return OfferInconsistencyReport
  } else if (type === 'wrong_type') {
    return WrongType
  } else {
    throw `Unsupported component type: ${type}`
  }
}

const stepCompleted = () => {
  proceedToNextStep()
}

const stepBack = () => {
  if (isFirstStep.value) return

  goBackToPreviousStep()
}

const validateAnswerLibrary = () => {
  const library = sessionStorage.getItem(ANSWER_LIBRARY_STORAGE_KEY)

  if (library) {
    const parsed = JSON.parse(library)

    if (!parsed.answers.length || parsed.meta.version !== ANSWER_LIBRARY_VERSION) {
      resetQuestionnaire()
    }

    const diff = new Date().getTime() - new Date(parsed.meta.updatedAt).getTime()
    if (diff > 60 * 60 * 1000) {
      resetQuestionnaire()
    }
  }
}

const resetQuestionnaire = () => {
  sessionStorage.removeItem(ANSWER_LIBRARY_STORAGE_KEY)
  sessionStorage.removeItem(FINGERPRINT_STORAGE_KEY)
  sessionStorage.removeItem(PATH_STORAGE_KEY)

  questionnaireStore.$reset()
  answerStore.$reset()
}
</script>
